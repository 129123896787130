import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Content3 from "../../sections/requests/Content3";
import Content4 from "../../sections/requests/Content4";
import Content5 from "../../sections/requests/Content5";
import Content6 from "../../sections/requests/Content6";
import Content7 from "../../sections/requests/Content7";
import Content8 from "../../sections/requests/Content8";
import Content9 from "../../sections/requests/Content9";
import CTA from "../../components/CTA/CTA";

const Requests = () => {
  return (
    <>
      <PageWrapper>
        <Content5 className="pt-26 pt-lg-34 pb-10 pb-sm-5 pb-lg-14" />
        <Content3 className="pt-11 pt-lg-34 pb-13 pb-lg-28 pb-0 pb-lg-31" />
        <Content4 className="pt-11 pb-13 pb-lg-28 pb-0 pb-lg-31" />
        <Content6 className="pt-11 pb-13 pb-lg-28 pb-0 pb-lg-31" />
        <Content7 className="pt-11 pb-13 pb-lg-28 pb-0 pb-lg-31" />
        <CTA className="bg-buttercup py-10" />
        <Content9 className="pt-11 pt-lg-34 pb-13 pb-lg-28 pb-0 pb-lg-31" />
        <Content8 className="pt-11 pt-lg-34 pb-13 pb-lg-28 pb-0 pb-lg-31" />
      </PageWrapper>
    </>
  );
};
export default Requests;
