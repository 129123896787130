import React from "react";
import { Link } from "gatsby";

import imgBig from "../../assets/image/inner-page/png/requests-vacations.png";
import imgDP from "../../assets/image/inner-page/png/dot-pattern-black.png";
import imgLC from "../../assets/image/inner-page/png/left-circlehalf-shape.png";

const Content3 = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-9 col-xs-11">
              <div
                className="section-title"
                data-aos="fade-left"
                data-aos-delay={500}
                data-aos-once="true"
              >
                <h2 className="font-size-11 mb-10 mb-lg-20">Vacaciones</h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            {/* Right */}
            <div
              className="col-xl-6 col-lg-6 col-md-5 col-xs-8"
              data-aos="fade-right"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="content-image-group-3 mb-17 mb-lg-0 mr-xl-16 shadow-9">
                <div className="img-1">
                  <img src={imgBig} alt="" />
                  <div className="img-2">
                    <img className="w-100" src={imgDP} alt="" />
                  </div>
                  <div className="img-3 rounded-10">
                    <img className="w-100 opacity-4" src={imgLC} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* Right */}
            {/* Left */}
            <div
              className="col-lg-6 col-md-7 col-xs-10"
              data-aos="fade-left"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="mr-lg-10 mr-xl-25">
                <p className="heading-default-color font-size-6 mb-10">
                  Consulta de las vacaciones sin asignar por año, donde tu
                  empleado/a podrá solicitarte donde quiere disfrutarlas.
                </p>
                <p className="heading-default-color font-size-6 mb-11">
                  Tus empleados/as sabrán la situación de su solicitud en todo
                  momento. Pueden adjuntar todos los documentos que necesiten
                  presentarte y editarla si aún no ha sido procesada por ti.
                </p>
                <div className="btn-group mb-12">
                  <Link className="btn btn-blue-3 rounded-5" to="/contact">
                    Saber más
                  </Link>
                </div>
                <div className="">
                  <p className="font-size-3 line-height-20 mb-7 mb-5 mr-xs-17 mr-md-11 mr-lg-17">
                    Si prefieres gestionar tu las vacaciones, puedes configurar
                    Timeview para que tu equipo no pueda solicitarlas.
                  </p>
                </div>
              </div>
            </div>
            {/* Left End */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Content3;
