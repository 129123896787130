import React from "react";
import { Link } from "gatsby";

import imgMain from "../../assets/image/inner-page/png/requests-content-1.png";
import imgDot from "../../assets/image/inner-page/png/dot-bg.png";
import imgIcon from "../../assets/image/svg/requests.svg";

const Content = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            {/* Image Section */}
            <div className="col-xl-6 col-lg-5 col-md-7 col-xs-10">
              <div
                className="l1-content-image-group-1 mb-10 mb-lg-0"
                data-aos="fade-right"
                data-aos-duration={500}
                data-aos-once="true"
              >
                <img className="w-100" src={imgMain} alt="" />
                <div className="abs-img-1">
                  <img className="w-100" src={imgDot} alt="" />
                </div>
                {/* l1-content-card-1 Image Section */}
                <div
                  className="l1-content-card-1 bg-white shadow-3"
                  data-aos="zoom-in-left"
                  data-aos-duration={900}
                  data-aos-delay={300}
                  data-aos-once="true"
                >
                  <div className="abs-card-icon">
                    <span className="bg-gradient-5 circle-73">
                      <img src={imgIcon} alt="" />
                    </span>
                  </div>
                  <div className="abs-content pl-10 pr-10 pt-9">
                    <div className="border-bottom-dotted mb-8">
                      <h5 className="font-size-8 font-weight-medium mb-0 text-dark-cloud">
                        Hay una nueva solicitud
                      </h5>
                      <p className="font-size-4 mb-7 text-bali-gray">
                        hace 2 minutos
                      </p>
                    </div>
                    <div className="media align-items-center mb-7">
                      <div className="company-info">
                        <h6 className="font-size-6 font-weight-normal mb-0 text-dark-cloud lineo">
                          Carmen ha solicitado una ausencia de{" "}
                          <strong>asuntos personales</strong>.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Content Section */}
            <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
              <div
                className="ml-lg-10 ml-xl-12 mt-lg-n20 pr-lg-10 pr-xl-10"
                data-aos="fade-left"
                data-aos-duration={800}
                data-aos-delay={300}
                data-aos-once="true"
              >
                <h6 className="font-size-5 text-dark-green mb-5 mb-lg-9 font-weight-normal">
                  Solicitudes de empleados
                </h6>
                <h3 className="font-size-11 mb-5 mb-lg-8 font-weight-medium pr-sm-20 pr-md-16 pr-lg-0">
                  Si necesitan algo, lo sabrás
                </h3>
                <p className="font-size-6 mb-0">
                  Gestiona de manera cómoda todas las solicitudes de vacaciones,
                  ausencias, jornadas excedidas o incidencias de un vistazo
                  tanto para ellos/as como para ti.
                </p>
                {/* newsletter */}
                <div
                  className="mt-8 mt-lg-16"
                  data-aos="fade-left"
                  data-aos-duration={800}
                  data-aos-delay={500}
                  data-aos-once="true"
                >
                  <Link to="/contact" className="btn btn-blue-3 rounded-5">
                    Contacta con nosotros
                  </Link>
                </div>
                {/* End newsletter */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
